import React from "react";
import { Header, Footer } from "../../components";
import Success from "./Success";

const OrderSuccessPage = () => {
  return (
    <div>
      <Header />
      <Success />
      <Footer />
    </div>
  );
};



export default OrderSuccessPage;
