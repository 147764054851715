import React from "react";
import styles from "../../styles/styles";
import CountDown from "./CountDown";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";

const EventCard = ({ active, data }) => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const addToCartHandler = (data) => {
    const isItemExists = cart && cart.find((i) => i._id === data._id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };
  return (
    <>
      <div
        className={`${styles.section} bg-white lg:w-full rounded-lg my-4 ${
          active ? "unset" : "mb-8"
        } lg:flex p-3`}
      >
        <div className="w-full lg:w-[15%]">
          <img
            src={data.images[0]}
            alt={data.name}
            className="w-full h-auto rounded-lg"
          />
        </div>
        <div className="w-full lg:w-[70%] flex flex-col justify-center p-3">
          <h2 className="text-xl font-bold text-[#333] mb-2">{data.name}</h2>
          <p className="text-gray-700 mb-2">{data.description.length > 200 ? data.description.slice(0, 200) + "..." : data.description}</p>
          <div className="flex items-center justify-between mb-2">
            <div className="flex">
              <h5 className="font-semibold text-red-500 pr-2 line-through">
                Ghc {data.originalPrice}
              </h5>
              <h5 className="font-bold text-md text-[#333] font-Roboto">
                Ghc {data.discountPrice}
              </h5>
            </div>
            <span className="text-[#44a55e]">{data.sold_out} sold</span>
          </div>
          <CountDown data={data} />
          <div className="flex items-center mt-2">
            <Link
              to={`/product/${data._id}?isEvent=true`}
              className={`${styles.button} text-white text-sm`}
            >
              See Details
            </Link>
            <div
              className={`${styles.button} text-white ml-2 cursor-pointer text-sm`}
              onClick={() => addToCartHandler(data)}
            >
              Add to Cart
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventCard;
