// import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { server } from "../../../server";
import styles from "../../../styles/styles";
import Loader from "../../Layout/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductsShop } from "../../../redux/actions/product";
import { BiEdit } from "react-icons/bi";
import { IoLogOutOutline } from "react-icons/io5";
import SEO from "../../seo/SeoTag";
import Cookies from "js-cookie";
import axios from "../../../service/Api/http";

const ShopInfo = ({ isOwner }) => {
  const [data, setData] = useState({});
  const { products } = useSelector((state) => state.products);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProductsShop(id));
    setIsLoading(true);
    axios
      .get(`${server}/shop/get-shop-info/${id}`)
      .then((res) => {
        setData(res.data.shop);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [dispatch, id]);

  const logoutHandler = async () => {
    axios.get(`${server}/shop/logout`);
    Cookies.remove("seller_token");
    localStorage.removeItem("seller_token");
    window.location.reload();
  };

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const averageRating = totalRatings / totalReviewsLength || 0;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full lg:flex-shrink-0 py-5 justify-between">
          <SEO
            title={data.name}
            description={`Welcome to ${data.name}'s shop we cover varieties of products.${data.description} `}
            type={"website"}
            name={data.name}
          />
          <div className="w-full flex item-center justify-center mb-4">
            <img
              src={data.avatar}
              alt={data.name}
              className="w-[50px] h-[50px] object-cover rounded-full"
            />
          </div>
          <h3 className="text-center py-2 text-[20px] capitalize">
            {data.name}'s Shop
          </h3>
          <p className="text-[16px] text-[#000000a6] p-[10px] flex items-center lg:flex-col lg:items-start">
            {data.description}
          </p>

          <div className="p-3">
            <h5 className="font-[600]">Address</h5>
            <h4 className="text-[#000000a6]">{data.address}</h4>
          </div>
          <div className="p-3">
            <h5 className="font-[600]">Phone Number</h5>
            <h4 className="text-[#000000a6]">{data.phoneNumber}</h4>
          </div>
          <div className="p-3">
            <h5 className="font-[600]">Total Products</h5>
            <h4 className="text-[#000000a6]">{products && products.length}</h4>
          </div>
          <div className="p-3">
            <h5 className="font-[600]">Shop Ratings</h5>
            <h4 className="text-[#000000b0]">{averageRating}/5</h4>
          </div>
          <div className="p-3">
            <h5 className="font-[600]">Joined On</h5>
            <h4 className="text-[#000000b0]">
              {data?.createdAt?.slice(0, 10)}
            </h4>
          </div>
          {isOwner && (
            <div className="py-3 px-4">
              <Link to="/settings">
                <div
                  className={`${styles.button} !w-full text-center !h-[42px] !rounded-[5px]`}
                >
                  <span className="text-white hidden md:block">Edit Shop</span>
                  <BiEdit size={30} color="white" className="md:hidden" />
                </div>
              </Link>
              <div
                className={`${styles.button} !w-full !h-[42px] text-center !rounded-[5px]`}
                onClick={logoutHandler}
              >
                <span className="text-white hidden md:block">Log Out</span>
                <IoLogOutOutline
                  size={30}
                  color="white"
                  className="md:hidden"
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ShopInfo;
