import React from "react";
import { Link } from "react-router-dom";

const FearuredProductBanner = () => {
  const reviews = [
    {
      id: 1,
      comment:
        "Received my order surprisingly fast. The product exceeded my expectations. A satisfied customer, no doubt!",
      source: "Anonymous Shopper",
    },
    {
      id: 2,
      comment:
        "Had to make a return due to size issues. The return process was incredibly straightforward, leading me to place an order for two new items!",
      source: "Unknown Customer",
    },
    {
      id: 3,
      comment:
        "With summer holidays underway, I'm considering ordering more shirts. The convenience is unmatched, and the quality is always reliable.",
      source: "Mystery Shopper",
    },
  ];
  return (
    <div className="relative overflow-hidden bg-white">
      {/* Decorative background image and gradient */}
      <div aria-hidden="true" className="absolute inset-0">
        <div className="absolute inset-0 mx-auto max-w-7xl overflow-hidden xl:px-8">
          <img
            src="/assets/images/banner.jpg"
            alt=""
            className="h-full w-full object-cover object-center"
          />
        </div>
        <div className="absolute inset-0 bg-white bg-opacity-75" />
        <div className="absolute inset-0 bg-gradient-to-t from-white via-white" />
      </div>

      {/* Callout */}
      <section
        aria-labelledby="sale-heading"
        className="relative mx-auto flex max-w-7xl flex-col items-center px-4 pt-32 text-center sm:px-6 lg:px-8"
      >
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <h2
            id="sale-heading"
            className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl"
          >
            Unlock Savings: Get 25% Off in Our Exclusive One-Time Sale!
          </h2>
          <p className="mx-auto mt-4 max-w-xl text-xl text-gray-600">
            Explore exclusive discounts with a 25% off during our one-time sale!
            Act fast as our limited releases won't be restocked. Snag your
            favorites before they vanish! Shop now for unmatched savings!
          </p>
          <Link
            to="/events"
            className="mt-6 inline-block w-full rounded-md border border-transparent bg-gray-900 px-8 py-3 font-medium text-white hover:bg-gray-800 sm:w-auto"
          >
            Get access to our one-time sale
          </Link>
        </div>
      </section>

      {/* Testimonials */}
      <section
        aria-labelledby="testimonial-heading"
        className="relative mx-auto max-w-7xl px-4 py-24 sm:px-6 lg:px-8 lg:py-32"
      >
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <h2
            id="testimonial-heading"
            className="text-2xl font-bold tracking-tight text-gray-900"
          >
            What are people saying?
          </h2>

          <div className="mt-16 space-y-16 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
            {reviews.map((testimonial) => (
              <blockquote key={testimonial.id} className="sm:flex lg:block">
                <svg
                  width={24}
                  height={18}
                  viewBox="0 0 24 18"
                  aria-hidden="true"
                  className="flex-shrink-0 text-gray-300"
                >
                  <path
                    d="M0 18h8.7v-5.555c-.024-3.906 1.113-6.841 2.892-9.68L6.452 0C3.188 2.644-.026 7.86 0 12.469V18zm12.408 0h8.7v-5.555C21.083 8.539 22.22 5.604 24 2.765L18.859 0c-3.263 2.644-6.476 7.86-6.451 12.469V18z"
                    fill="currentColor"
                  />
                </svg>
                <div className="mt-8 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-10">
                  <p className="text-lg text-gray-600">{testimonial.comment}</p>
                  <cite className="mt-4 block font-semibold not-italic text-gray-900">
                    {testimonial.source}
                  </cite>
                </div>
              </blockquote>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default FearuredProductBanner;
