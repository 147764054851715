import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { backend_url, server } from "../../../server";
// import axios from "axios";
import axios from "../../../service/Api/http";

import { toast } from "react-toastify";
import { AiOutlineMessage } from "react-icons/ai";
import { Header } from "../../../components";

const ShopForMe = () => {
  const { user, isAuthenticated } = useSelector((state) => state.user);

  const [shoppingAgents, setShoppingAgents] = useState([]);
  const navigate = useNavigate();

  const handleMessageSubmit = async (agentId) => {
    // console.log(agentId, user._id);
    if (isAuthenticated) {
      const groupTitle = agentId + user._id;
      const userId = user._id;
      const sellerId = agentId;
      await axios
        .post(`${server}/conversation/create-new-conversation`, {
          groupTitle,
          userId,
          sellerId,
        })
        .then((res) => {
          navigate(`/inbox?${res.data.conversation._id}`);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      toast.error("Please login to create a conversation");
    }
  };

  // get all agents from the system

  const getAgents = async () => {
    try {
      const response = await axios.get(`${server}/user/get-shopping-agents/`);
      setShoppingAgents(response.data.agents);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAgents();
  }, []);
  return (
    <div className="w-full">
      <Header activeHeading={6} />
      <div className="max-w-6xl mx-auto py-8">
        <h1 className=" text-xl py-3 font-semibold font-Poppins">
          You do not have time? Message our Shopping Agents
        </h1>
        <p className="mb-3">
          We have agents available who can take your order and handle all your
          shopping for you from the best and reliable shops.
        </p>
        {shoppingAgents.map((agent, index) => (
          <div
            key={index}
            onClick={() => handleMessageSubmit(agent._id)}
            className="bg-black text-white flex border border-white items-center cursor-pointer rounded-lg px-2 py-2 my-2 capitalize w-1/2"
          >
            <AiOutlineMessage className="ml-1 hidden xl:block mr-2" />
            <h1 className="flex-row">Message Agent {agent.name}</h1>
          </div>
        ))}
        {/* {handleMessageSubmit} */}
      </div>
    </div>
  );
};

export default ShopForMe;
