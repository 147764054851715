import React from 'react'
import { DashboardHeader, Footer, OrderDetails } from '../../../components'


const ShopOrderDetails = () => {
  return (
    <div>
         <DashboardHeader />
         <OrderDetails />
          <Footer />
    </div>
  )
}

export default ShopOrderDetails