export const server =
  process.env.NODE_ENV === "production"
    ? "https://api.shopsvibe.shop/api/v2"
    : "http://localhost:9000/api/v2";

export const backend_url =
  process.env.NODE_ENV === "production"
    ? "https://api.shopsvibe.shop/"
    : "http://localhost:9000/";

export const socket_url =
  process.env.NODE_ENV === "production"
    ? "https://shopvibes-socket.up.railway.app"
    : "http://localhost:4000/";
