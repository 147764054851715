import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../../server";
import { toast } from "react-toastify";
import { RxAvatar } from "react-icons/rx";
import { State } from "country-state-city";
import { FaArrowAltCircleRight } from "react-icons/fa";

const ShopCreate = () => {
  const navigate = useNavigate();
  const [regionsData, setregionsData] = useState([
    ...State.getStatesOfCountry("GH"),
  ]);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [zipCode, setZipCode] = useState();
  const [avatar, setAvatar] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  // console.log(regionsData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (password !== confirmPassword) {
      return toast.error("Password and confirm password do not match");
    }
    if (password.trim().length < 6) {
      return toast.error("Password must be at least 6 characters");
    }
    if (address.trim() == "") return toast.error("Select a valid address");
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const newForm = new FormData();

    newForm.append("file", avatar);
    newForm.append("name", name);
    newForm.append("email", email);
    newForm.append("password", password);
    newForm.append("zipCode", zipCode);
    newForm.append("address", address);
    newForm.append("description", description);
    newForm.append("phoneNumber", phoneNumber);
    axios
      .post(`${server}/shop/create-shop`, newForm, config)
      .then((res) => {
        toast.success(res.data.message);
        setName("");
        setEmail("");
        setPassword("");
        setAvatar();
        setZipCode();
        setAddress("");
        setDescription("");
        setPhoneNumber();
        setLoading(false);
        navigate("/shop/login");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setLoading(false);
      });
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setAvatar(file);
  };

  return (
    <div className="min-h-screen flex w-full justify-center items-center xl:p-5">
      <div
        className="h-screen xl:h-[90dvh] xl:rounded-2xl xl:shadow-lg relative max-w-[1300px] w-full"
        style={{
          backgroundImage: `url(https://wallpaperaccess.com/full/709030.jpg)`,
        }}
      >
        <div className="bg-black bg-opacity-50 absolute xl:rounded-2xl inset-0"></div>
        <div className="absolute top-[50%] translate-y-[-50%] z-50 flex gap-6 justify-center md:justify-between w-full px-4  lg:px-[4rem] items-end">
          <div className="text-white w-[500px] hidden md:block">
            <h3 className="text-[28px]">
              Welcome! Sign up to become a vendor and start selling your
              products with us today.
            </h3>
            <div className="flex mt-4 gap-4">
              <Link
                to="/shop-login"
                className="w-full glass-secondary p-4 hover:shadow-xl hover:bg-primary_color hover:scale-105 ease-in transition-all"
              >
                <h4 className="text-xs mb-2">Already have a shop?</h4>
                <div className="flex items-center gap-2">
                  Sign in
                  <FaArrowAltCircleRight />
                </div>
              </Link>
              <Link
                to="/login"
                className="w-full glass-secondary p-4 hover:shadow-xl hover:bg-primary_color hover:scale-105 ease-in transition-all"
              >
                <h4 className="text-xs mb-2">Already have an account?</h4>
                <div className="flex items-center gap-2">
                  Sign in
                  <FaArrowAltCircleRight />
                </div>
              </Link>
            </div>
          </div>
          <div className="glass py-8 px-4 shadow sm:rounded-lg max-h-[80dvh] overflow-y-scroll nice-scroll-bar max-w-md sm:px-10 sm:w-full xl:max-w-lg lg:max-w-md">
            <div className="mb-8">
              <img className="h-10 w-auto" src="/logo.png" alt="Shop Vibes" />
              <h2 className="mt-4 text-3xl font-extrabold text-gray-100">
                Register as a Vendor.
              </h2>
            </div>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-white"
                >
                  Shop Name
                </label>
                <div className="mt-1">
                  <input
                    type="name"
                    name="name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-white"
                >
                  Phone Number (For contact and sms)
                </label>
                <div className="mt-1">
                  <input
                    type="number"
                    name="phone-number"
                    required
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-white"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-white"
                >
                  Shop description (About your shop or what you sell)
                </label>
                <div className="mt-1">
                  <textarea
                    type="text"
                    name="description"
                    autoComplete="description"
                    required
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-white"
                >
                  Address (Select where you are located )
                </label>
                <div className="mt-1">
                  <select
                    name=""
                    required
                    value={address}
                    id=""
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  >
                    <option value={""}>-Select-</option>
                    {regionsData.map((item, index) => (
                      <option value={item.name} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-white"
                >
                  Zip Code
                </label>
                <div className="mt-1">
                  <input
                    type="number"
                    name="zipcode"
                    required
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-white"
                >
                  Password
                </label>
                <div className="mt-1 relative">
                  <input
                    type={visible ? "text" : "password"}
                    name="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  {visible ? (
                    <AiOutlineEye
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(false)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(true)}
                    />
                  )}
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-white"
                >
                  confirm Password
                </label>
                <div className="mt-1 relative">
                  <input
                    type={visible ? "text" : "password"}
                    name="password"
                    autoComplete="current-password"
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  {visible ? (
                    <AiOutlineEye
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(false)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(true)}
                    />
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="avatar"
                  className="block text-sm font-medium text-white"
                ></label>
                <div className="mt-2 flex items-center cursor-pointer">
                  <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
                    {avatar ? (
                      <img
                        src={URL.createObjectURL(avatar)}
                        alt="avatar"
                        className="h-full w-full object-cover rounded-full"
                      />
                    ) : (
                      <RxAvatar className="h-8 w-8 text-white" />
                    )}
                  </span>
                  <label
                    htmlFor="file-input"
                    className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                  >
                    <span>Upload a shop profile</span>
                    <input
                      type="file"
                      name="avatar"
                      id="file-input"
                      required
                      onChange={handleFileInputChange}
                      className="sr-only w-full"
                    />
                  </label>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary_color hover:bg-primary_color_hover ease-in transition-all"
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
              </div>
              <div className="md:hidden">
                <div className={`${styles.noramlFlex} w-full text-white`}>
                  <h4>Already have a shop ?</h4>
                  <Link
                    to="/shop-login"
                    className="text-primary_color hover:underline pl-2"
                  >
                    Sign in
                  </Link>
                </div>
                <div className={`${styles.noramlFlex} w-full text-white`}>
                  <h4>Already have an account?</h4>
                  <Link
                    to="/login"
                    className="text-primary_color hover:underline pl-2"
                  >
                    Sign in
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopCreate;
