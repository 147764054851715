import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect } from "react";
import { AiOutlineEye, AiOutlineMore } from "react-icons/ai";
import { Link } from "react-router-dom";

import axios from "axios";
import { server } from "../../server";
import { useState } from "react";
import showAlertWithConfirmation from "../../service/shared/Alert";

const AllProducts = () => {
  const [data, setData] = useState([]);
  const [productId, setProductId] = useState("");

  useEffect(() => {
    axios
      .get(`${server}/product/admin-all-products`, { withCredentials: true })
      .then((res) => {
        setData(res.data.products);
      });
  }, []);
  const handleMakeProductPromotion = async () => {
    try {
      console.log(productId);
      const res = await axios.put(`${server}/product/promotion/${productId}`, {
        withCredentials: true,
      });
      console.log(res);
      setData(res.data.products);
    } catch (err) {
      console.log(err);
    }
  };

  const handleMakeProductFeatured = async () => {
    try {
      console.log(productId);
      const res = await axios.put(`${server}/product/featured/${productId}`, {
        withCredentials: true,
      });
      setData(res.data.products);
    } catch (err) {
      console.log(err);
    }
  };

  const handleMakeProductBestSelling = async () => {
    try {
      console.log(productId);
      const res = await axios.put(
        `${server}/product/bestSelling/${productId}`,
        {
          withCredentials: true,
        }
      );
      setData(res.data.products);
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    { field: "id", headerName: "Product Id", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "price",
      headerName: "Price",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "Stock",
      headerName: "Stock",
      type: "number",
      minWidth: 80,
      flex: 0.5,
    },

    {
      field: "sold",
      headerName: "Sold out",
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "isPromotion",
      flex: 0.8,
      minWidth: 100,
      headerName: "is Promotion",
      type: "boolean",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setProductId(params.id);
                showAlertWithConfirmation(
                  "warning",
                  "Best promotion",
                  "Are you sure you to mame this product promotion?",
                  handleMakeProductPromotion
                );
              }}
            >
              <AiOutlineMore size={20} />
            </Button>
          </>
        );
      },
    },
    {
      field: "bestSelling",
      flex: 0.8,
      minWidth: 100,
      headerName: "bestSelling",
      type: "boolean",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={(e) => {
                e.preventDefault();
                console.log(params.id);
                setProductId(params.id);
                showAlertWithConfirmation(
                  "warning",
                  "Best selling",
                  "Are you sure you to mame this product best selling?",
                  handleMakeProductBestSelling
                );
              }}
            >
              <AiOutlineMore size={20} />
            </Button>
          </>
        );
      },
    },
    {
      field: "featured",
      flex: 0.8,
      minWidth: 100,
      headerName: "featured",
      type: "boolean",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setProductId(params.id);
                showAlertWithConfirmation(
                  "warning",
                  "Best featured",
                  "Are you sure you to mame this product featured?",
                  handleMakeProductFeatured
                );
              }}
            >
              <AiOutlineMore size={20} />
            </Button>
          </>
        );
      },
    },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/product/${params.id}`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  data &&
    data.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        price: "Ghc " + item.discountPrice,
        Stock: item.stock,
        sold: item?.sold_out,
      });
    });

  return (
    <>
      <div className="w-full mx-8 pt-1 mt-10 bg-white">
        <DataGrid
          rows={row}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
      </div>
    </>
  );
};

export default AllProducts;
