import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";

const DropDown = ({ categoriesData, setDropDown }) => {
  const closeDropDown = () => {
    setDropDown(false);
  };

  return (
    <div className="pb-4 w-[270px] bg-[#fff] absolute z-30 rounded-b-md shadow-sm">
      {categoriesData &&
        categoriesData.map((category, index) => (
          <Link
            key={index}
            to={`/products?category=${category.title}`}
            className={`${styles.noramlFlex}`}
            onClick={closeDropDown}
          >
            <img
              src={category.image_Url}
              style={{
                width: "25px",
                height: "25px",
                objectFit: "contain",
                marginLeft: "10px",
                userSelect: "none",
              }}
              alt=""
            />
            <h3 className="m-3 cursor-pointer select-none">{category.title}</h3>
          </Link>
        ))}
    </div>
  );
};

export default DropDown;
