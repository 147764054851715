import React, { useEffect, useState } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { EditEvent,DashboardSideBar,DashboardHeader } from "../../../components";


const ShopEditEvents = () => {
  const { allEvents } = useSelector((state) => state.events);
  const { id } = useParams();
  const [data, setData] = useState(null);

  console.log("gggg", allEvents);
  useEffect(() => {
    const data = allEvents && allEvents.find((i) => i._id === id);
    setData(data);
  }, [allEvents, id]);

  return (
    <div>
      <DashboardHeader />
      <div className="flex items-center justify-between w-full">
        <div className="w-[330px]">
          <DashboardSideBar active={6} />
        </div>
        <div className="w-full justify-center flex">
          {data ? (
            <EditEvent event={data} />
          ) : (
            <div className="text-2xl font-semibold justify-center text-gray-500">
              Event does not exist
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShopEditEvents;
