import React from "react";
// import FearuredProductBanner from "../components/Route/FeaturedProduct/FearuredProductBanner";
import { SEO, Footer, Header, Events, Hero, Categories, BestDeals, FeaturedProduct } from "../../components";

const HomePage = () => {
  return (
    <div>
      <SEO
        title="Welcome to Our Shops - Discover Amazing Products" // Descriptive title for the home page
        description="Explore our curated selection of high-quality products. From electronics to fashion, find everything you need at our shop. Shop now and experience exceptional service and value." // Detailed description highlighting the variety and quality of products available
        type="website" // Use the default type for the home page
      />
      <Header activeHeading={1} />
      <Hero />
      <Categories />
      <BestDeals />
      {/* <FearuredProductBanner /> */}
      <Events />
      <FeaturedProduct />
      {/* <Sponsored /> */}
      <Footer />
    </div>
  );
};

export default HomePage;
