import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styles from "../../styles/styles";
import {
  Footer,
  Header,
  SEO,
  Loader,
  ProductCard,
  CategoryFilter,
} from "../../components";

const ProductsPage = () => {
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [location, setLocation] = useState([]);
  const [sort, setSort] = useState("");

  // console.log(allProducts);
  useEffect(() => {
    let d = [];

    if (
      categoryData === null &&
      categories.length === 0 &&
      location.length == 0
    ) {
      setData(allProducts);
    } else {
      if (categories.length && location.length) {
        d =
          allProducts &&
          allProducts.filter(
            (i) =>
              (i.category === categoryData ||
                categories.some((cat) => cat === i.category)) &&
              location.some((cat) => cat === i.shop.address)
          );
      } else if (categories.length) {
        d =
          allProducts &&
          allProducts.filter(
            (i) =>
              i.category === categoryData ||
              categories.some((cat) => cat === i.category)
          );
      } else if (location.length) {
        d =
          data &&
          data.filter((i) => location.some((cat) => cat === i.shop.address));
      }
      setData(d);
    }

    if (sort == "Newest") {
      let data = d;
      const mostRecentDate = new Date(
        Math.max(...data.map((item) => new Date(item.createdAt).getTime()))
      );
      const oneWeekBeforeMostRecentDate = new Date(mostRecentDate);
      oneWeekBeforeMostRecentDate.setDate(mostRecentDate.getDate() - 7);
      data =
        d &&
        d.filter((i) => {
          const itemDate = new Date(i.createdAt);
          return itemDate >= oneWeekBeforeMostRecentDate;
        });
      setData(data);
    } else if (sort == "Best Rating") {
      let data = d;
      data =
        d &&
        d.filter((i) => {
          const reviewInfo =
            i.reviews.length && i.reviews.filter((review) => review.rating > 3);
          return reviewInfo.length >= i.reviews.length;
        });
      setData(data);
    } else if (sort == "Most Popular") {
      let data = d;
      data = d && d.filter((i) => i.bestSelling == true);
      setData(data);
    }
    window.scrollTo(0, 0);
  }, [allProducts, searchParams, categoryData, categories, location, sort]);

  return (
    <>
      <SEO
        title="Discover a Wide Range of Products at Our Shops" // Descriptive title highlighting the variety of products
        description="Explore our diverse collection of high-quality products, including electronics, clothing, accessories, and more. Shop now and find exactly what you need." // Detailed description emphasizing the range and quality of products available
        type="product.catalog" // Use a more specific type to indicate that this page represents a catalog of products
      />

      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Header activeHeading={3} />
          <br />
          <br />
          <div className={`${styles.section}`}>
            <h1 className="text-2xl font-bold mb-4">Discover Our Products</h1>
            <p className="bg-white rounded-lg p-4 mb-4">
              Welcome to our product page, where innovation meets convenience.
              Dive into a world of possibilities as you explore our latest
              collection of carefully curated products. We take pride in
              offering you a diverse range of items designed to enhance your
              lifestyle. From cutting-edge electronics to stylish accessories,
              each product in our collection has been handpicked for its
              quality, functionality, and aesthetic appeal. Our commitment is to
              provide you with the best, ensuring that every purchase exceeds
              your expectations.
            </p>
            <CategoryFilter
              categoryFilters={categories}
              getCategoryFilters={setCategories}
              locationFilters={location}
              getLocationFilters={setLocation}
              setSort={setSort}
              sort={sort}
            />
            <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
              {data &&
                data.map((i, index) => <ProductCard data={i} key={index} />)}
            </div>
            {data && data.length === 0 ? (
              <div className="flex-1 min-h-full justify-center items-center">
                <h1 className="text-center w-full pb-[100px] text-[20px] ">
                  No products Found!
                </h1>
              </div>
            ) : null}
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default ProductsPage;
