import Cookies from "js-cookie";

export default function setCookie(name, value, days) {
  var expires = "";
  var date = new Date();
  date.setTime(date.getTime() + 9 * 24 * 60 * 60 * 1000);
  if (days) {
    expires = "; expires=" + date.toUTCString();
  }
  // // Add SameSite attribute with value Strict
  // var sameSite = "; SameSite=Strict";
  // document.cookie =
  //   name + "=" + (value || "") + expires + "; path=/" + sameSite;
  Cookies.set(name, value, { sameSite: "strict", secure: true, expires: date });
}
