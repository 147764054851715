import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ShopCreate, SEO } from "../../../components";



const ShopCreatePage = () => {
  const navigate = useNavigate();
  const { isSeller, seller } = useSelector((state) => state.seller);

  useEffect(() => {
    if (isSeller === true) {
      navigate(`/shop/${seller._id}`);
    }
  }, []);
  return (
    <div>
      <SEO
        title="Become a Vendor - Start Selling with Us" // Title inviting users to sign up as vendors
        description="Join our platform as a vendor and reach a wider audience. Sign up now to showcase your products, grow your business, and connect with customers looking for unique offerings." // Description emphasizing the benefits of becoming a vendor on your platform
        type="website" // Use the default type for signup pages
      />

      <ShopCreate />
    </div>
  );
};

export default ShopCreatePage;
