import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { RxAvatar } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import SEO from "../seo/SeoTag";
import { FaArrowAltCircleRight } from "react-icons/fa";

const Singup = () => {
  const [email, setEmail] = useState("");
  const [referalEmail, setReferalEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setAvatar(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    if (password !== confirmPassword) {
      setLoading(false);
      return toast.error("Passwords do not match");
    }

    const newForm = new FormData();

    newForm.append("file", avatar);
    newForm.append("name", name);
    newForm.append("email", email);
    newForm.append("password", password);
    newForm.append("referalEmail", referalEmail);

    await axios
      .post(`${server}/user/create-user`, newForm, config)
      .then((res) => {
        toast.success(res.data.message);
        setName("");
        setEmail("");
        setPassword("");
        setReferalEmail("");
        setAvatar();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="min-h-screen flex w-full justify-center items-center xl:p-5">
      <SEO
        title="Create a Customer Account - Join Our Community" // Title inviting users to sign up for a customer account
        description="Join our community of satisfied customers. Sign up for a free account and unlock exclusive benefits, receive updates on new arrivals, and enjoy personalized recommendations." // Description emphasizing the advantages of creating a customer account
        type="website" // Use the default type for signup pages
      />

      <div
        className="h-screen xl:h-[90dvh] xl:rounded-2xl xl:shadow-lg relative max-w-[1300px] w-full"
        style={{
          backgroundImage: `url(https://wallpaperaccess.com/full/709030.jpg)`,
        }}
      >
        <div className="bg-black bg-opacity-50 absolute xl:rounded-2xl inset-0"></div>
        <div className="absolute top-[50%] translate-y-[-50%] z-50 flex gap-6 justify-center md:justify-between w-full px-4  lg:px-[4rem] items-end">
          <div className="text-white w-[500px] hidden md:block">
            <h3 className="text-[28px]">
              Welcome! Please sign up to join our community and start shopping
              with us today.
            </h3>
            <div className="flex mt-4 gap-4">
              <Link
                to="/login"
                className="glass-secondary w-[190px] p-4 hover:shadow-xl hover:bg-primary_color hover:scale-105 ease-in transition-all"
              >
                <h4 className="text-xs mb-2">Already a member?</h4>
                <div className="flex items-center gap-2">
                  Sign in
                  <FaArrowAltCircleRight />
                </div>
              </Link>
            </div>
          </div>
          <div className="glass py-8 px-4 shadow sm:rounded-lg max-h-[80dvh] overflow-y-scroll nice-scroll-bar max-w-md sm:px-10 sm:w-full xl:max-w-lg lg:max-w-md">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <img className="h-10 w-auto" src="/logo.png" alt="Shop vines" />
                <h2 className="mt-4 text-2xl font-bold leading-9 tracking-tight text-gray-100">
                  Sign up to create an account
                </h2>
                <p className="mt-2 text-sm leading-6 text-white md:hidden">
                  Already a member?
                  <Link
                    to="/login"
                    className="text-primary_color hover:underline pl-2"
                  >
                    Sign in
                  </Link>
                </p>
              </div>

              <div className="mt-8">
                <div>
                  <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-white"
                      >
                        Full Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="text"
                          autoComplete="name"
                          required
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-white"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          type="email"
                          name="email"
                          autoComplete="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-white"
                      >
                        Password
                      </label>
                      <div className="mt-1 relative">
                        <input
                          type={visible ? "text" : "password"}
                          name="password"
                          autoComplete="current-password"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                        {visible ? (
                          <AiOutlineEye
                            className="absolute right-2 top-2 cursor-pointer"
                            size={25}
                            onClick={() => setVisible(false)}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            className="absolute right-2 top-2 cursor-pointer"
                            size={25}
                            onClick={() => setVisible(true)}
                          />
                        )}
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-white"
                      >
                        Confirm Password
                      </label>
                      <div className="mt-1 relative">
                        <input
                          type={visible ? "text" : "password"}
                          name="confirmPassword"
                          autoComplete="current-password"
                          required
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                        {visible ? (
                          <AiOutlineEye
                            className="absolute right-2 top-2 cursor-pointer"
                            size={25}
                            onClick={() => setVisible(false)}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            className="absolute right-2 top-2 cursor-pointer"
                            size={25}
                            onClick={() => setVisible(true)}
                          />
                        )}
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-white"
                      >
                        Referal Email address
                      </label>
                      <div className="mt-1">
                        <input
                          type="email"
                          name="referalEmail"
                          autoComplete="email"
                          value={referalEmail}
                          onChange={(e) => setReferalEmail(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="avatar"
                        className="block text-sm font-medium text-white"
                      ></label>
                      <div className="mt-2 flex items-center">
                        <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
                          {avatar ? (
                            <img
                              src={URL.createObjectURL(avatar)}
                              alt="avatar"
                              className="h-full w-full object-cover rounded-full"
                            />
                          ) : (
                            <RxAvatar className="h-8 w-8 text-white" />
                          )}
                        </span>
                        <label
                          htmlFor="file-input"
                          className="ml-5 flex items-center cursor-pointer hover:bg-slate-100 justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white "
                        >
                          <span>Upload a file</span>
                          <input
                            type="file"
                            name="avatar"
                            id="file-input"
                            accept=".jpg,.jpeg,.png"
                            onChange={handleFileInputChange}
                            className="sr-only"
                          />
                        </label>
                      </div>
                    </div>

                    <div>
                      <button
                        disabled={loading}
                        type="submit"
                        className={
                          "group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary_color hover:bg-primary_color_hover ease-in transition-all"
                        }
                      >
                        {loading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Singup;
