import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { EditProduct,DashboardSideBar,DashboardHeader } from "../../../components";


const ShopEditProduct = () => {
  const { allProducts } = useSelector((state) => state.products);
  const { allEvents } = useSelector((state) => state.events);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [searchParams] = useSearchParams();
  const eventData = searchParams.get("isEvent");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (eventData !== null) {
      const data = allEvents && allEvents.find((i) => i._id === id);
      setData(data);
    } else {
      const data = allProducts && allProducts.find((i) => i._id === id);
      setData(data);
    }
    setLoading(false);
  }, [allProducts, allEvents, eventData, id]);
  if (loading) {
    return <div className="font-semibold tracking-wider">Loading...</div>;
  }
  return (
    <div>
      <DashboardHeader />
      <div className="flex items-center justify-between w-full">
        <div className="w-[80px] 800px:w-[330px]">
          <DashboardSideBar active={12} />
        </div>
        <div className="w-full justify-center flex">
          {data ? (
            <EditProduct product={data} />
          ) : (
            <div>
              <h1 className="text-2xl font-semibold text-gray-600">
                Please select a valid product
              </h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShopEditProduct;
