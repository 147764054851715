import { React, useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
import { server } from "../../../server";
import { toast } from "react-toastify";
import setCookie from "../../../utils/setcookie";
import axios from "../../../service/Api/http";
import { FaArrowAltCircleRight } from "react-icons/fa";

const ShopLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    localStorage.removeItem("seller_token");

    await axios
      .post(`${server}/shop/login-shop`, {
        email,
        password,
      })
      .then((res) => {
        const result = res.data;
        setCookie("seller_token", result.token, 90);
        localStorage.setItem("seller_token", result.token);

        toast.success("Login Success!");
        navigate("/dashboard");
        window.location.reload(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
    setLoading(false);
  };

  return (
    <div className="min-h-screen flex w-full justify-center items-center xl:p-5">
      <div
        className="h-screen xl:h-[90dvh] xl:rounded-2xl xl:shadow-lg relative max-w-[1300px] w-full"
        style={{
          backgroundImage: `url(https://wallpaperaccess.com/full/709030.jpg)`,
        }}
      >
        <div className="bg-black bg-opacity-50 absolute xl:rounded-2xl inset-0"></div>
        <div className="absolute top-[50%] translate-y-[-50%] z-50 flex gap-6 justify-center md:justify-between w-full px-4  lg:px-[4rem] items-end">
          <div className="text-white w-[500px] hidden md:block">
            <h3 className="text-[28px]">
              Welcome back! Please sign in to access your vendor account and
              manage your store.
            </h3>
            <div className="flex mt-4 gap-4">
              <Link
                to="/shop-create"
                className="w-[190px] glass-secondary p-4 hover:shadow-xl hover:bg-primary_color hover:scale-105 ease-in transition-all"
              >
                <h4 className="text-xs mb-2">Don't have an account?</h4>
                <div className="flex items-center gap-2">
                  Sign Up
                  <FaArrowAltCircleRight />
                </div>
              </Link>
            </div>
          </div>
          <div className="glass py-8 px-4 shadow sm:rounded-lg max-w-md sm:px-10 sm:w-full xl:max-w-lg lg:max-w-md">
            <div className="mb-8">
              <img className="h-10 w-auto" src="/logo.png" alt="Shop Vibes" />
              <h2 className="mt-6 text-3xl font-extrabold text-gray-100">
                Login to your shop
              </h2>
            </div>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-white"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-white"
                >
                  Password
                </label>
                <div className="mt-1 relative">
                  <input
                    type={visible ? "text" : "password"}
                    name="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  {visible ? (
                    <AiOutlineEye
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(false)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(true)}
                    />
                  )}
                </div>
              </div>
              <div className={`${styles.noramlFlex} justify-between gap-6`}>
                <div className={`${styles.noramlFlex}`}>
                  <input
                    type="checkbox"
                    name="remember-me"
                    id="remember-me"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-white"
                  >
                    Remember me
                  </label>
                </div>
                <div className="text-sm">
                  <a
                    href=".forgot-password"
                    className="font-medium text-primary_color hover:underline"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary_color hover:bg-primary_color_hover ease-in transition-all"
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
              </div>
              <div className={`${styles.noramlFlex} w-full text-white md:hidden`}>
                <h4>Not have any account?</h4>
                <Link to="/shop-create" className="text-primary_color hover:underline pl-2">
                  Sign Up
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopLogin;
