import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SEO, ShopLogin } from '../../../components';

const ShopLoginPage = () => {
  const navigate = useNavigate();
  const { isSeller,isLoading } = useSelector((state) => state.seller);

  useEffect(() => {
    if(isSeller === true){
      navigate(`/dashboard`);
    }
  }, [isLoading,isSeller])
  return (
    <div>
      <SEO
        title="Vendor Login - Access Your Dashboard" // Descriptive title for the vendor login page
        description="Log in to your vendor account to manage your store, upload products, track sales, and engage with customers. Sign in now and take control of your business." // Description highlighting the features and capabilities of vendor accounts
        type="website" // Use the default type for login pages
      />
        <ShopLogin />
    </div>
  )
}

export default ShopLoginPage