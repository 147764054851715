import React, { useState } from "react";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { useEffect } from "react";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "../../Products/Ratings";
import { BiMapAlt } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";

const ProductCard = ({ data, isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [data._id, wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  return (
    <>
      <div className="relative w-full h-[370px] bg-white rounded-lg shadow-sm p-3 cursor-pointer overflow-hidden transition-transform transform hover:scale-110">
        <Link
          to={`${
            isEvent === true
              ? `/product/${data._id}?isEvent=true`
              : `/product/${data._id}`
          }`}
        >
          <img
            src={data.images && data.images[0]}
            alt=""
            className="w-full h-[170px] object-cover rounded-t-lg"
          />
        </Link>
        <Link to={`/shop/preview/${data?.shop._id}`}>
          <h5 className="mt-2 text-gray-700 hover:underline capitalize text-sm">
            {data.shop.name}'s shop
          </h5>
          <h5 className="mt-2 text-gray-700 hover:underline capitalize text-sm flex items-center">
            <CiLocationOn className="mr-2" /> {data.shop.address}
          </h5>
        </Link>
        <Link
          to={`${
            isEvent === true
              ? `/product/${data._id}?isEvent=true`
              : `/product/${data._id}`
          }`}
        >
          <h4 className="pb-2 font-medium text-gray-900">
            {data.name.length > 30 ? data.name.slice(0, 30) + "..." : data.name}
          </h4>
          <div className="flex items-center mb-1">
            <Ratings rating={data?.ratings} />
          </div>
          <div>
            <span className="text-gray-600">
              {data.description.substring(0, 20)}...
            </span>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex space-x-1">
              <h5 className="font-bold text-[#f56f5e]">
                Ghc{" "}
                {data.originalPrice === 0
                  ? data.originalPrice
                  : data.discountPrice}
              </h5>
              {data.originalPrice > 0 && (
                <h4 className="text-gray-500 line-through">
                  {data.originalPrice + " Ghc"}
                </h4>
              )}
            </div>
            <span className="text-[#68d284] text-[17px] font-[400]">
              {data?.sold_out} sold
            </span>
          </div>
        </Link>

        {/* Side options/icons */}
        <div className="absolute top-0 right-0 p-3 bg-white/80 group rounded-b">
          {click ? (
            <div className="bg-white">
              <AiFillHeart
                size={22}
                className="cursor-pointer bg-white"
                onClick={() => removeFromWishlistHandler(data)}
                color={click ? "red" : "#333"}
                title="Remove from wishlist"
              />
            </div>
          ) : (
            <AiOutlineHeart
              size={22}
              className="cursor-pointer"
              onClick={() => addToWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Add to wishlist"
            />
          )}
          <AiOutlineEye
            size={22}
            className="cursor-pointer mt-3"
            onClick={() => setOpen(!open)}
            color="#333"
            title="Quick view"
          />
          <AiOutlineShoppingCart
            size={25}
            className="cursor-pointer mt-3"
            onClick={() => addToCartHandler(data._id)}
            color="#444"
            title="Add to cart"
          />
        </div>
      </div>
      {open && <ProductDetailsCard setOpen={setOpen} data={data} />}
    </>
  );
};

export default ProductCard;
