import React from 'react'
import { Footer, Header, UserOrderDetails } from '../../components';

const OrderDetailsPage = () => {
  return (
    <div>
        <Header />
        <UserOrderDetails />
        <Footer />
    </div>
  )
}

export default OrderDetailsPage