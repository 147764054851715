import axios from "axios";

axios.defaults.withCredentials = true;
const responseSuccessHandler = (response) => {
  return response;
};

const token_user = window.localStorage.getItem("token");
const token_seller = window.localStorage.getItem("seller_token");
if (token_user) {
  axios.defaults.headers["Authorization"] = `Bearer ${token_user}`;
} else if (token_seller) {
  axios.defaults.headers["Authorization"] = `Bearer ${token_seller}`;
}

console.log("token_user", token_user, token_seller);

const responseErrorHandler = (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem("user");
    window.location.href = "/login";
  }

  return Promise.reject(error);
};

// axios.interceptors.response.use(
//   (response) => responseSuccessHandler(response),
//   (error) => responseErrorHandler(error)
// );

export default axios;
