import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import {
  AiOutlineCheck,
  AiOutlineDelete,
  AiOutlineEye,
  AiOutlineMore,
} from "react-icons/ai";
import { Button } from "@material-ui/core";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
// import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { getAllSellers } from "../../redux/actions/sellers";
import { Link } from "react-router-dom";
import axios from "../../service/Api/http";

const AllSellers = () => {
  const dispatch = useDispatch();
  const { sellers } = useSelector((state) => state.seller);
  const [open, setOpen] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    dispatch(getAllSellers());
  }, [dispatch]);

  const handleDelete = async (id) => {
    try {
      await axios
        .delete(`${server}/shop/delete-seller/${id}`, { withCredentials: true })
        .then((res) => {
          toast.success(res.data.message);
        });

      dispatch(getAllSellers());
    } catch (error) {
      toast.success(error.message);
    }
  };
  const handleApprove = async (id) => {
    try {
      const res = await axios.post(`${server}/shop/approve-seller/${id}`);
      toast.success(res.data.message);
      dispatch(getAllSellers());
    } catch (error) {
      toast.success(error.message);
    }
  };
  const handleRejection = async (id) => {
    try {
      const res = await axios.post(`${server}/shop/unapprove-seller/${id}`);
      toast.success(res.data.message);
      dispatch(getAllSellers());
    } catch (error) {
      toast.success(error.message);
    }
  };

  const columns = [
    { field: "id", headerName: "Seller ID", minWidth: 150, flex: 0.7 },

    {
      field: "name",
      headerName: "name",
      minWidth: 130,
      flex: 0.7,
      sortable: true,
    },
    {
      field: "email",
      headerName: "Email",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "address",
      headerName: "Seller Address",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "status",
      headerName: "Approved",
      type: "boolean",
      minWidth: 100,
      flex: 0.7,
      sortable: true,
    },
    {
      field: "joinedAt",
      headerName: "joined on",
      type: "text",
      minWidth: 100,
      flex: 0.8,
    },
    {
      field: "active",
      headerName: "Active",
      type: "boolean",
      minWidth: 60,
      flex: 0.8,
    },
    {
      field: "subscription",
      headerName: "Tier",
      type: "text",
      minWidth: 60,
      flex: 0.8,
    },
    {
      field: "  ",
      // flex: 1,
      minWidth: 50,
      headerName: "view",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/shop/preview/${params.id}`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
    {
      field: "    ",
      // flex: 1,
      minWidth: 100,
      headerName: "Action",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={() => setUserId(params.id) || setOpenApprove(true)}
            >
              <AiOutlineMore size={20} />
            </Button>
          </>
        );
      },
    },
    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "Delete Seller",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => setUserId(params.id) || setOpen(true)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];
  sellers &&
    sellers.forEach((item) => {
      row.push({
        id: item._id,
        name: item?.name,
        email: item?.email,
        joinedAt: item.createdAt.slice(0, 10),
        address: item.address,
        status: item.verified,
        active: item.active,
        subscription: item.subscriptionMode,
      });
    });

  return (
    <div className="w-full flex justify-center pt-5">
      <div className="w-[97%]">
        <h3 className="text-[22px] font-Poppins pb-2">All Vendors</h3>
        <div className="w-full min-h-[45vh] bg-white rounded">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
        {open && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setOpen(false)} />
              </div>
              <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                Are you sure you wanna delete this user?
              </h3>
              <div className="w-full flex items-center justify-center">
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
                  onClick={() => setOpen(false)}
                >
                  cancel
                </div>
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] ml-4`}
                  onClick={() => setOpen(false) || handleDelete(userId)}
                >
                  confirm
                </div>
              </div>
            </div>
          </div>
        )}
        {openApprove && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setOpenApprove(false)} />
              </div>
              <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                What do you want to do to this vendor?
              </h3>
              <div className="w-full flex items-center justify-center">
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
                  onClick={() => setOpenApprove(false)}
                >
                  cancel
                </div>
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] ml-4`}
                  onClick={() => setOpenApprove(false) || handleApprove(userId)}
                >
                  Approve
                </div>
                <div
                  className={`${styles.button} text-white text-[18px] !bg-red-600 !h-[42px] ml-4`}
                  onClick={() =>
                    setOpenApprove(false) || handleRejection(userId)
                  }
                >
                  Unapprove
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllSellers;
