import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SEO, Login } from "../../components/index.js";

const LoginPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    if (isAuthenticated === true) {
      navigate("/");
    }
  }, []);

  return (
    <div>
      <SEO
        title="Customer Login - Sign in to Your Account" // Descriptive title for the customer login page
        description="Log in to your customer account and access exclusive features, track orders, and manage your profile. Sign in now to enjoy a seamless shopping experience." // Description highlighting the benefits and features of customer accounts
        type="website" // Use the default type for login pages
      />

      <Login />
    </div>
  );
};

export default LoginPage;
