import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../styles/styles";
import { Footer, SEO, Header, Loader, ProductCard } from "../../components";

const BestSellingPage = () => {
  const [data, setData] = useState([]);
  const { allProducts, isLoading } = useSelector((state) => state.products);

  useEffect(() => {
    const allProductsData = allProducts ? [...allProducts] : [];
    const sortedData = allProductsData?.sort((a, b) => b.sold_out - a.sold_out);
    setData(sortedData);
  }, [allProducts]);

  return (
    <>
      <SEO
        title="Best Selling Products - Shop Our Top Picks" // Title highlighting the popularity of products
        description="Discover our best-selling products and find out why they're customer favorites. Shop now and experience the quality, value, and satisfaction that our top picks offer." // Description showcasing the appeal and value of best-selling products
        type="product.catalog" // Use a specific type to indicate that this page represents a catalog of products
      />

      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Header activeHeading={2} />
          <br />
          <br />
          <div className={`${styles.section}`}>
            <h1 className="text-2xl font-bold mb-4">
              Discover Our Best Sellers - Top Picks Chosen by You!
            </h1>
            <p className="bg-white rounded-lg p-4 mb-4">
              Welcome to our Best Sellers page, where you'll find a curated
              selection of our most popular and highly-rated products. These are
              the favorites of our valued customers, chosen for their quality,
              functionality, and style. Whether you're looking for the latest
              trends or timeless classics, our Best Sellers collection has
              something for everyone.
            </p>
            <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
              {data &&
                data.map((i, index) => <ProductCard data={i} key={index} />)}
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default BestSellingPage;
