import Swal from "sweetalert2";

// Generic SweetAlert function with confirmation callback
/**
 *
 * @param {*('success', 'error', 'warning', etc.)} type
 * @param {*} title
 * @param {*} text
 * @param {*} confirmCallback
 */
const showAlertWithConfirmation = (
  type = "warning",
  title = "Are you sure?",
  text = "You won't be able to revert this!",
  confirmCallback
) => {
  Swal.fire({
    background: "#FF8A3D",
    icon: type, // Type of alert ('success', 'error', 'warning', etc.)
    title: title, // Title of the alert
    text: text, // Alert message text
    showCancelButton: true, // Shows the "Cancel" button
    confirmButtonText: "Yes, do it!", // Text for the confirmation button
    cancelButtonText: "No, cancel!", // Text for the cancel button
    reverseButtons: true, // Reverses the order of the buttons
  }).then((result) => {
    if (result.isConfirmed) {
      // If the user confirms, run the callback function
      if (confirmCallback && typeof confirmCallback === "function") {
        confirmCallback()
          .then((res) => {
            Swal.fire("Done!", "Your action has been confirmed.", "success");
          })
          .catch((err) => {
            Swal.fire("Cancelled", "Your action has been cancelled.", "error");
          });
        Swal.fire("Done!", "Your action has been confirmed.", "success");
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled", "Your action has been cancelled.", "error");
    }
  });
};

export default showAlertWithConfirmation;
