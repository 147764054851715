import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { brandingData, categoriesData, perks } from "../../../static/data";
import styles from "../../../styles/styles";

const Categories = () => {
  const navigate = useNavigate();

  return (
    <div
      className={`${styles.section} rounded-lg mb-12 h-full mt-4 sm:px-4`}
      id="categories"
    >
      {/* <div
        className={`relative box-content h-40 xl:h-full overflow-x-auto py-2 xl:overflow-visible my-8 scrollbar-hide`}
      >
        <div className="flex flex-row">
          {brandingData &&
            brandingData.map((i, index) => (
              <div className="flex items-start mb-4 md:mb-0 w-full" key={index}>
                {i.icon}
                <div className="md:ml-3">
                  <h3 className="font-bold text-base md:text-lg">{i.title}</h3>
                  <p className="text-xs md:text-sm">{i.Description}</p>
                </div>
              </div>
            ))}
        </div>
      </div> */}

      <div className="my-14">
        <div className="w-full gap-2 flex bg-white p-2 overflow-x-scroll no-scroll rounded">
          {perks &&
            perks.map((category, index) => (
              <div
                key={category.name}
                className={`${
                  index == perks.length - 1 && "border-none"
                } flex gap-2 items-center border-r min-w-[300px] `}
              >
                <div className="h-[70px]">
                  <img
                    className="h-full object-cover w-full"
                    src={category.imageUrl}
                    alt={category.name}
                  />
                </div>
                <div>
                  <h3 className="font-[500] text-[18px] capitalize">
                    {category.name}
                  </h3>
                  <p className="text-xs">{category.description}</p>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* Category section */}
      <section aria-labelledby="category-heading" className="my-4">
        <div className="sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0">
          <h2 className={`${styles.heading}`} id="category-heading">
            Categories
          </h2>
          <a
            href="#/"
            className="hidden text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block"
          >
            Browse all categories
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>

        <div className="mt-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 w-full rounded h-[300px] py-4 overflow-y-scroll no-scroll">
            {categoriesData &&
              categoriesData.map((category) => (
                <div
                  className="flex justify-between px-5 p-4 border cursor-pointer bg-white transition-all ease-in hover:scale-100 hover:shadow-md"
                  key={category.id}
                  onClick={() =>
                    navigate(`/products?category=${category.title}`)
                  }
                >
                  <div className="flex flex-col justify-evenly">
                    {console.log(category)}
                    <div>
                      <h3 className="font-[500] text-[16px] capitalize">
                        {category.title}
                      </h3>
                    </div>
                    <p className="text-primary_color">Shop Now!</p>
                  </div>
                  <div className="h-[100px]">
                    <img
                      src={category.image_Url}
                      alt="item image"
                      className="h-full w-full object-cover"
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="mt-6 px-4 sm:hidden">
          <a
            href="#/"
            className="block text-sm font-semibold text-indigo-600 hover:text-indigo-500"
          >
            Browse all categories
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </section>
    </div>
  );
};

export default Categories;
