import React from "react";
import { useSelector } from "react-redux";
import styles from "../../styles/styles";
import { SEO, Footer,Loader, Header, EventCard } from "../../components";

const EventsPage = () => {
  const { allEvents, isLoading } = useSelector((state) => state.events);
  return (
    <>
      <SEO
        title="Exciting Events - Don't Miss Out!" // Title emphasizing the excitement of events
        description="Stay updated on the latest events hosted by our shop. From exclusive product launches to special promotions, discover what's happening and join us for unforgettable experiences." // Description providing details about the events and encouraging participation
        type="event" // Use a specific type to indicate that this page represents events
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Header activeHeading={4} />
          <div className={`${styles.section} py-10`}>
            <div className="py-5">
              <h1 className="text-2xl font-bold mb-4">
                Exclusive Launch Event
              </h1>
              <p className="bg-white rounded-lg p-4 mb-4">
                Be the trendsetter! Dive into the world of innovation at our
                Exclusive Launch Event. We're thrilled to unveil a collection of
                cutting-edge products that redefine style, function, and
                sophistication. From futuristic gadgets to revolutionary
                designs, this event is your passport to the latest trends in the
                industry. Immerse yourself in an exclusive showcase of brand-new
                products meticulously curated for those who crave the
                extraordinary!.
              </p>
            </div>

            {allEvents.length > 0 &&
              allEvents.map((event) => (
                <EventCard key={event._id} active={true} data={event} />
              ))}
          </div>
        </>
      )}
      <Footer />
    </>
  );
};

export default EventsPage;
