import React from "react";
import { useSelector } from "react-redux";
import styles from "../../styles/styles";
import EventCard from "./EventCard";
import LoadingSpinner from "../loading/Loading";

const Events = () => {
  const { allEvents, isLoading } = useSelector((state) => state.events);

  return (
    <div>
      <div className={`${styles.section}`}>
        <div className={`${styles.heading}`}>
          <h1>Popular Events</h1>
        </div>
        {isLoading && <LoadingSpinner />}

        {!isLoading && (
          <div className="w-full grid items-center">
            {allEvents && allEvents.length !== 0 ? (
              <EventCard data={allEvents && allEvents[0]} />
            ) : (
              <h1>No Event available</h1>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Events;
