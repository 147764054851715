import React from "react";
import { Header, Footer } from "../../components";
import Faq from "./Faq";

const FAQPage = () => {
  return (
    <div>
      <Header activeHeading={5} />
      <Faq />
      <Footer />
    </div>
  );
};



export default FAQPage;
