import React, { useEffect, useState } from "react";
import { Footer, Header, SEO } from "../../components";
import { Link } from "react-router-dom";

const Main = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "https://wallpaperaccess.com/full/2484090.jpg",
    "https://wallpaperaccess.com/full/8279872.jpg",
    "https://wallpaperaccess.com/full/8033213.jpg",
    "https://wallpaperaccess.com/full/6895806.jpg",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <div className="about-page bg-gray-100 text-gray-800">
      <SEO title={"About Us"} type="website"/>
      <Header />
      <section
        className="hero-section bg-cover bg-center h-screen flex items-center justify-center relative transition-all duration-1000"
        style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
      >
        <div className="bg-black bg-opacity-50 absolute inset-0"></div>
        <div className="text-center text-white relative z-0">
          <h1 className="text-6xl font-bold mb-4">
            About <span className="text-primary_color">Us</span>
          </h1>
          <p className="text-2xl">
            Discover <span className="text-primary_color">Our</span> Journey and
            Values
          </p>
        </div>
      </section>

      <section className="company-overview py-20 px-4 md:px-20 lg:px-40">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-6">Who We Are</h2>
          <p className="text-xl leading-relaxed mb-6">
            We are a passionate team dedicated to providing the best online
            shopping experience. Founded in 2024, our mission is to bring you
            the highest quality products from around the world. We believe in
            sustainability, quality, and customer satisfaction.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-white shadow-lg p-6 rounded-lg">
              <h3 className="text-2xl font-semibold mb-4">Our Mission</h3>
              <p>
                To revolutionize the way people shop online by offering an
                unparalleled selection of products, exceptional customer
                service, and innovative shopping experiences.
              </p>
            </div>
            <div className="bg-white shadow-lg p-6 rounded-lg">
              <h3 className="text-2xl font-semibold mb-4">Our Vision</h3>
              <p>
                To be the world's most customer-centric company, where customers
                can find and discover anything they might want to buy online.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="our-values bg-gray-50 py-20 px-4 md:px-20 lg:px-40">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-6">Our Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white shadow-lg p-6 rounded-lg flex flex-col justify-center items-center">
              <img
                className="h-[140px] w-[140px] object-cover mb-4"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGn-U1cEOD3_y3eqbnPrsklKt6BENxypl1aPwEdIiYvaSBFWeFSnjnfFAuUXXeIR6m618&usqp=CAU"
                alt="icon"
              />
              <h3 className="text-2xl font-semibold mb-4">Integrity</h3>
              <p>
                We adhere to the highest ethical standards in all our
                operations, ensuring transparency and honesty in every
                transaction.
              </p>
            </div>
            <div className="bg-white shadow-lg p-6 rounded-lg flex flex-col justify-center items-center">
              <img
                className="h-[140px] w-[140px] object-cover mb-4"
                src="https://cdn-icons-png.freepik.com/512/10108/10108295.png"
                alt="icon"
              />
              <h3 className="text-2xl font-semibold mb-4">Innovation</h3>
              <p>
                We continuously seek new and creative ways to meet and exceed
                the expectations of our customers.
              </p>
            </div>
            <div className="bg-white shadow-lg p-6 rounded-lg flex flex-col justify-center items-center">
              <img
                className="h-[140px] w-[140px] object-cover mb-4"
                src="https://i.pinimg.com/564x/ab/d3/fd/abd3fdbf74f54dc52461687b9581b397.jpg"
                alt="icon"
              />
              <h3 className="text-2xl font-semibold mb-4">Customer Focus</h3>
              <p>
                Our customers are at the heart of everything we do. We are
                committed to providing exceptional service and support.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="team-section py-20 px-4 md:px-20 lg:px-40">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-6">Meet Our Team</h2>
          <p className="text-xl leading-relaxed mb-6">
            Our team is comprised of experienced professionals dedicated to
            making your shopping experience delightful.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white shadow-lg p-6 rounded-lg">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThnKpKC-sq9gwtW-ZU0xEq-03i_swcUMqWecI-anHl79aOBEcadpJ8Y5lmMbGJmxza3kk&usqp=CAU"
                alt="Team Member"
                className="w-full h-64 object-cover rounded-lg mb-4"
              />
              <h3 className="text-2xl font-semibold">Agbo Manfuji</h3>
              <p className="text-gray-600">CEO & Founder</p>
            </div>
            <div className="bg-white shadow-lg p-6 rounded-lg">
              <img
                src="https://www.sciencealert.com/images/2023/05/black_persons_face_digital_scan.jpg"
                alt="Team Member"
                className="w-full h-64 object-cover rounded-lg mb-4"
              />
              <h3 className="text-2xl font-semibold">Agbogbli Isaac</h3>
              <p className="text-gray-600">Chief Marketing Officer</p>
            </div>
            <div className="bg-white shadow-lg p-6 rounded-lg">
              <img
                src="https://imageio.forbes.com/specials-images/imageserve/64bf1828c39d451c82b88edd/Futuristic-and-technological-scanning-of-face-for-facial-recognition/960x0.jpg?format=jpg&width=960"
                alt="Team Member"
                className="w-full h-64 object-cover rounded-lg mb-4"
              />
              <h3 className="text-2xl font-semibold">Zormelo Alex</h3>
              <p className="text-gray-600">Head of Product</p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-50 py-20 px-4 md:px-20 lg:px-40">
        <div className="max-w-7xl mx-auto text-center mb-8">
          <h2 className="text-4xl font-bold mb-6">
            Building Trust with Our Customers and Sellers
          </h2>
          <p className="text-xl leading-relaxed mb-6">
            At ShopVibes, we prioritize trust and transparency. Our commitment
            to authenticity, security, and customer satisfaction sets us apart.
          </p>
          <marquee behavior="alternate" direction="left">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="p-6 rounded-lg flex flex-col justify-center items-center">
                <img
                  className="h-[140px] w-[140px] rounded-full object-cover mb-4"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvVbj9UtLhDtL_CiwCU8phZAhnI_cYDIjPWwd-zhNG5g&s"
                  alt="icon"
                />
                <h3 className="font-semibold">100% Authentic Products</h3>
              </div>
              <div className="p-6 rounded-lg flex flex-col justify-center items-center">
                <img
                  className="h-[140px] w-[140px] rounded-full object-cover mb-4"
                  src="https://img.freepik.com/premium-vector/gold-secure-shield-badge-design_92086-393.jpg"
                  alt="icon"
                />
                <h3 className="font-semibold">Secure Shopping Experience</h3>
              </div>
              <div className="p-6 rounded-lg flex flex-col justify-center items-center">
                <img
                  className="h-[140px] w-[140px] rounded-full object-cover mb-4"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9lHdp7Fix2yt5StxnMSvRXjNZUAfxy_euMVGZP_9cRA&s"
                  alt="icon"
                />
                <h3 className="font-semibold">Customer Satisfaction</h3>
              </div>
            </div>
          </marquee>
        </div>
        <div className="flex flex-wrap justify-center gap-8 pt-8">
          <Link
            to={"/products"}
            className="bg-primary_color px-4 py-2 rounded-sm hover:text-white shadow-lg transition ease-in"
          >
            Shop Now
          </Link>
          <Link
            to={"/shop-create"}
            className="bg-primary_color px-4 py-2 rounded-sm hover:text-white shadow-lg transition ease-in"
          >
            Become a seller
          </Link>
          <Link
            to={"/shop-for-me"}
            className="bg-primary_color px-4 py-2 rounded-sm hover:text-white shadow-lg transition ease-in"
          >
            Shop for me
          </Link>
          <Link
            to={"/events"}
            className="bg-primary_color px-4 py-2 rounded-sm hover:text-white shadow-lg transition ease-in"
          >
            Events
          </Link>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Main;
