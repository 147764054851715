import React from "react";

const LoadingSpinner = () => {
  return (
    <div className="text-black flex " disabled>
      <svg
        className="animate-spin h-5 w-5 mr-3 textwhite"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="currentColor"
      >
        <circle
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          fill="currentColor"
          d="M4 12a8 8 0 018-8V2.83A10 10 0 002 12h2zM4 12a8 8 0 018 8V21.17A10 10 0 0022 12h-2zM20 12a8 8 0 01-8 8V21.17A10 10 0 0022 12h-2z"
        ></path>
      </svg>
      Loading...
    </div>
  );
};

export default LoadingSpinner;
