import React from 'react'
import { Header, Footer, TrackOrder } from '../../components';

const TrackOrderPage = () => {
  return (
    <div>
        <Header />
        <TrackOrder />
        <Footer />
    </div>
  )
}

export default TrackOrderPage